<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="loginFailed")
    NowLoading(:show="showNowLoading")
    div.wrap-module-invitation.f.fh
      div.invitation-content
        div
          div.wrap-confirm-label.mb40.f.fc
            div(v-if="attendee")
              span.bold {{attendee.name}}
              span さんの予定を送信します
            div(v-else)
              span 新規の参加者として予定を送信します
          SignIn(@signInSuccess="signInSuccess"
            :googleSignInLabel="'Googleカレンダー連携で連絡先と日時を入力'")
          div.info-sign-in.mt20
            span.text-center.block.fz10.mb4 Googleカレンダーをお持ちの方はMeetAwesomeと
            span.text-center.block.fz10.mb4 連携させることで自動でミーティング日時の調整を行います。
            span.text-center.block.fz10 （すでにアカウントをお持ちの方もこちら）
        div.wrap-or.py30
          div.f.fm
            div.or-border
            span.px12 OR
            div.or-border
        div.wrap-anonymous-sign-in
          div.f.fc.mb24
            span.fz14.mr4 もしくは
            span(@click="onManual").btn.fz14.cursor-pointer 手動で連絡先と参加可能な日時を入力
          div.info-sign-in
            span.fz10.block.text-center.mb4 Googleカレンダーを連携しない場合は可能な日程を
            span.fz10.block.text-center 手動で選択することでAIが自動日程調整を開始します
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-invitation {
  width: 100%;
  min-height: 100vh;
  // padding-top: $header_height;
  .invitation-content {
    width: 100%;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .wrap-inviter {
      width: $base_width_percent;
      margin: 0 auto;
    }
    .wrap-or {
      .or-border {
        width: 100%;
        // border-bottom: $basic_border;
        height: 0.6px;
        background: $secondary_text_color;
      }
    }
    .info-sign-in {
      color: $primary_text_color;
      // color: $secondary_text_color;
    }
    .wrap-anonymous-sign-in {
      .btn {
        color: $secondary_text_color;
        border-bottom: solid 1px $secondary_text_color;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.wrap-module-invitation {
  .firebaseui-card-content {
    padding: 0 !important;
  }
}
</style>

<script>
import database from '@/database'
import { firebase } from '@/components/utils/firebase'
import { updateMeetingAttendee } from '@/services/functions'
import {
  sendTrackingEvent,
  signUpForTracking,
  identifyTrackedUser
} from '@/components/utils/eventTracker'
import { createNamespacedHelpers } from 'vuex'
const {
  mapState: mapStateAuth,
  mapMutations: mapMutationsAuth,
  mapActions: mapActionsAuth
} = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import SignIn from '@/components/sign-in/firebase-sign-in-ui'
import NowLoading from '@/components/shared/NowLoading'

export default {
  components: {
    Auth,
    SignIn,
    NowLoading
  },
  computed: {
    ...mapStateAuth(['uid', 'isAnonymous']),
    attendee() {
      return this.$route.params.attendeeIndex
        ? this.meeting.attendee[Number(this.$route.params.attendeeIndex)]
        : null
    }
  },
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inviter: null,
      showNowLoading: true
      // showDialog: false
    }
  },
  async created() {
    if (this.isAnonymous) this.signOut()

    // 新規メンバーでない場合
    if (this.attendee) {
      if (this.attendee.email) {
        let attendeeEvents = await database
          .meetingCollection()
          .getAttendeeEventsByEmail(this.$route.params.meetingId, this.attendee.email)
        if (!attendeeEvents.empty) {
          this.$router.push(
            `/send-events-complete/${this.$route.params.meetingId}/${this.attendee.email}`
          )
        }
      }
    }

    if (this.meeting.status === 'adjusted' || this.meeting.status === 'failed') {
      this.$router.push(`/meeting/${this.$route.params.meetingId}`)
    }

    this.showNowLoading = false
  },
  methods: {
    ...mapMutationsAuth(['updateAccessToken']),
    ...mapActionsAuth(['signInAnonymously', 'signOut']),
    async onLoggedIn() {
      // const meeting = await database.meetingCollection().findById(this.$route.params.meetingId)
      // if (meeting.createdBy === this.uid) {
      //   const me = meeting.attendee.filter((e) => e.email === this.$route.params.inviteeEmail)[0]
      //   if (me.responsed) {
      //     this.$router.push(`/meeting/${this.$route.params.meetingId}`)
      //   } else {
      //     this.$router.push(`/create-meeting/send-attendee-events/${this.$route.params.meetingId}`)
      //   }
      // }
      // 新規メンバーでない場合
      // if (this.attendee) {
      //   if (this.attendee.responsed) {
      //     this.$router.push(`/meeting/${this.$route.params.meetingId}`)
      //   } else {
      //     // this.$router.push(`/create-meeting/send-attendee-events/${this.$route.params.meetingId}`)
      //   }
      // } else {
      // }
      // this.showNowLoading = false
    },
    loginFailed () {
      console.log('loginFailed')
      this.showNowLoading = false
    },
    async signInSuccess ({ firebaseUser, token }) {
      this.$root.$emit('sendTrackingEventByClick', { 
        message: 'click_calendar_connection_btn',
        option: { meetingId: this.$route.params.meetingId }
      })

      this.showNowLoading = false

      this.updateAccessToken(token.accessToken)

      // await this.sleepByPromise(0.8)
      // let firebaseUser = await firebase.auth().currentUser
      // console.log('firebaseUser:', firebaseUser)

      if (firebaseUser.uid) {
        this.showNowLoading = true

        let user = await firebase.auth().currentUser
        let userDoc = await database.userCollection().findById(firebaseUser.uid)

        let params = {
          meetingId: this.$route.params.meetingId,
          attendee: {
            name: this.attendee.name,
            email: user.email
          }
        }

        if (!userDoc) {
          let userObj = {
            uid: user.uid,
            name: user.displayName ? user.displayName : user.email.split('@')[0],
            profile: 'No Profile',
            iconURL: user.photoURL,
            email: user.email,
            // emailAsInvitee: this.$route.params.inviteeEmail,
            isAnonymous: false,
            lastSignInTime: user.metadata.lastSignInTime,
            createdAt: user.metadata.creationTime,
            timeZone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Asia/Tokyo'
          }

          if (this.$route.params.inviteeEmail)
            userObj.emailAsInvitee = this.$route.params.inviteeEmail

          await database.userCollection().signUp(userObj, {
            idToken: token.idToken,
            accessToken: token.accessToken
          })

          params.attendee.email = user.email

          // mixpannel
          identifyTrackedUser(firebaseUser.uid)
          signUpForTracking(userObj)
          console.log('sign up user:', userObj)
        } else {
          await database.userCollection().setGoogleToken(firebaseUser.uid, {
            idToken: token.idToken,
            accessToken: token.accessToken,
            createdBy: firebaseUser.uid,
            createdAt: new Date()
          })

          const existingUser = await database.userCollection().findById(firebaseUser.uid)
          params.attendee.email = existingUser.email

          // mixpannel
          identifyTrackedUser(firebaseUser.uid)
        }

        await updateMeetingAttendee(params)
        this.$router.push(
          `/invitation/send-attendee-events/${this.$route.params.meetingId}/google/${params.attendee.email}`
        )

        this.showNowLoading = false

        // this.$router.push(
        //   `/invitation/send-attendee-events/${this.$route.params.meetingId}/google/${this.$route.params.inviteeEmail}`
        // )
      } // if (firebaseUser.uid)
    },
    async onManual() {
      this.$root.$emit('sendTrackingEventByClick', { 
        message: 'click_manual_input_btn',
        option: { meetingId: this.$route.params.meetingId }
      })

      this.showNowLoading = true

      let params
      // sign in anonymously
      if (!this.uid || this.isAnonymous) {
        // アカウントがないユーザーが手動での入力を選択した場合
        if (!this.isAnonymous) await this.signInAnonymously()
        const firebaseUser = await firebase.auth().currentUser

        let user = {
          uid: firebaseUser.uid,
          type: 'anonymous'
        }
        console.log('anonymousSignIn', user)

        // /invitation/input-contact/:meetingId/:attendeeIndex?
        if (this.$route.params.attendeeIndex) {
          // すでにemailが入力されているユーザーだった場合は連絡先入力を飛ばす
          // TO DO: SMS通知が出来次第、電話番号が入力されていなかった場合も考慮
          if (this.attendee.email !== '') {
            this.$router.push(
              `/invitation/send-attendee-events/${this.$route.params.meetingId}/manual/${this.attendee.email}`
            )
          } else {
            this.$router.push(
              `/invitation/input-contact/${this.$route.params.meetingId}/${this.$route.params.attendeeIndex}`
            )
          }
        } else {
          this.$router.push(`/invitation/input-contact/${this.$route.params.meetingId}`)
        }

        // mixpannel
        // signUpForTracking(user)
        // identifyTrackedUser(this.uid)
        // sendTrackingEvent(`${this.$route.path}:onManual`, user, this.uid)
      } else {
        // すでにアカウントを持っているユーザーが手動での入力を選択した場合
        const existingUser = await database.userCollection().findById(this.uid)
        params = {
          meetingId: this.$route.params.meetingId,
          attendee: {
            name: this.attendee.name,
            email: existingUser.email
          }
        }
        await updateMeetingAttendee(params)
        this.$router.push(
          `/invitation/send-attendee-events/${this.$route.params.meetingId}/manual/${existingUser.email}`
        )

        // mixpannel
        // identifyTrackedUser(this.uid)
        // sendTrackingEvent(`${this.$route.path}:onManual`, {}, this.uid)
      }

      this.showNowLoading = false
    },
    sleepByPromise(sec) {
      return new Promise((resolve) => setTimeout(resolve, sec * 1000))
    }
  }
}
</script>
