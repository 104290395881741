<template lang="pug">
  Auth(@loggedIn="onLoggedIn")
    Header(:content="headerContent")
    div.wrap-recommend-google-auth.f.fh
      ModuleInvitationRecommendGoogleAuth(v-if="meeting" :meeting="meeting")
</template>

<style lang="scss" scoped>
.wrap-recommend-google-auth {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header.vue'
import ModuleInvitationRecommendGoogleAuth from '@/components/module/ModuleInvitationRecommendGoogleAuth'

export default {
  components: {
    Auth,
    Header,
    ModuleInvitationRecommendGoogleAuth
  },
  data() {
    return {
      headerContent: {
        title: '日時・連絡先の入力方法を選択',
        left: {
          icon: 'mdi-chevron-left',
          to: `/invitation/lp/${this.$route.params.meetingId}`
        }
      },
      meeting: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    this.meeting = await database.meetingCollection().findById(this.$route.params.meetingId)
  },
  methods: {
    onLoggedIn() {}
  }
}
</script>
